import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {

  return (
    <header className="header bg-stellar1">
      <div className="container uppercase">
      <svg className="logo" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 32h12v2H19v-2ZM3 32h12v2H3v-2ZM3 0h12v2H3V0ZM19 0h12v2H19V0ZM3 16h12v2H3v-2ZM19 16h12v2H19v-2ZM18 3v12h-2V3h2ZM18 19v12h-2V19h2ZM2 3v12H0V3h2ZM2 19v12H0V19h2ZM34 3v12h-2V3h2ZM34 19v12h-2V19h2ZM12.5 14 4 5.5V4h1.5l8.5 8.5V14h-1.5ZM30 5.5 21.5 14H20v-1.5L28.5 4H30v1.5ZM28.5 30 20 21.5V20h1.5l8.5 8.5V30h-1.5ZM4 28.5l8.5-8.5H14v1.5L5.5 30H4v-1.5Z" fill="#fff"/></svg>
        <h1 className="py-12 xl:py-16">{siteTitle}</h1>
        <p className="h1 pb-12 xl:pb-16" style={{ maxWidth: `16ch` }}>Future-first digital design &amp; development</p>
      </div>

    </header >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  page: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  page: ``,
}

export default Header
